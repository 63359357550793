<template>
미니게임 연승/연패 이벤트
</template>

<script>
export default {
  name:"EventMiniWl"
}
</script>

<style scoped>

</style>
